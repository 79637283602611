@import "src/styles/mixins.module";

.body {
  user-select: none;
  @include flex;
  justify-content: flex-end;
  align-items: unset;
  margin-top: 6px;
  position: relative;
  z-index: 2;

  .label {
    margin-top: 1px;
    font-size: 14px;
    color: $deepgray;
  }

  .container {
    margin-left: 12px;
    background-color: white;
    position: relative;

    .header {
      @include flex;
      width: 67px;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 12px;
      font-weight: bold;
      color: $deepgray;
      text-align: center;
      padding-left: 11px;
      cursor: pointer;
      border-style: solid;
      border-color: #b5b5b5;
      border-radius: 7px;
      border-width: 1px;
      flex-wrap: nowrap;
      &--open {
        border-bottom-color: transparent;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .listcontainer {
      position: absolute;
      width: 100%;
      height: auto;
      background-color: white;

      border-style: solid;
      border-color: #b5b5b5;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      border-width: 1px;
      border-top: none;
      .list {
        max-height: 187px;
        overflow-y: auto;
        .listitem {
          padding-left: 11px;
          margin-top: 4px;
          margin-bottom: 4px;
          list-style: none;
          font-size: 12px;
          font-weight: 600;
          color: $deepgray;
          cursor: pointer;
          &:hover {
            background-color: $semiblue;
          }
        }
      }
    }
  }
}
