h1table {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 0.5rem !important;
  margin-top: 0 !important;
  display: block;
  
  padding-top: 5px; /* Some padding to push the header down a bit */
}

h2table {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em;
  font-weight: bold;
  color: #CC4C00;
  margin-bottom: 0.5rem !important;
  margin-top: 0 !important; 
  display: block;
}

.padded-header {
  padding-top: 30px;
}

.wider {
  width: 150px; /* Adjust the width as needed */
}

.input-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-row {
  display: flex;
  justify-content: space-between;
  align-items: bottom; /* This ensures vertical centering of items within the row */
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  flex: 1; 
  margin-right: 15px; 
}

.input-row label,
.input-row input,
.input-row button {
  margin: 0 10px;
  padding: 0;
}

.input-row input {
  border: 1px solid #ccc;
  padding: 5px 10px;
  width: 100%; 
}

.input-row button {
  width: 100%;
  margin: 0 10px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  background-color: #0056b3;
  transition: background-color 0.3s ease;
  color: white; /* Set the default text color for all buttons */
}

.input-row-button .cancel-button,
.input-row .buy-button {
  border-radius: 5px; /* Apply border-radius to both button types */
}

.input-row-button .cancel-button {
  background-color: #FFDDDD !important; /* Light red background color for "Cancel" button */
  color: #FF0000 !important; /* Dark red text color for "Cancel" button */
}

.input-row .buy-button {
  background-color: #0056b3;
}

.input-row button:hover {
  background-color: #0056b3;
}

.table-container {
  width: 100%;
  padding: 0px;
  overflow-x: auto;
  justify-content: center; /* Center horizontally */
}

.table-wrapper {
  width: fit-content;
  margin: 0 auto; /* Center horizontally */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

th {
  font-weight: bold;
  background-color: #f2f2f2;
  color: black;
  position: sticky;
  top: 0;
}

.table th {
  white-space: normal;
}

tbody tr:nth-child(even) {
  background-color: #03076b;
}

tbody tr:hover {
  background-color: #e6f7ff;
  color: black;
}

.table-container {
  position: relative;
  z-index: 1;
}

thead {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
  z-index: 1;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.items-per-page label,
.items-per-page select,
.page-numbers button {
  font-size: 2rem;
}

.page-numbers button.active {
  color: blue;
  font-size: 3rem;
}

.buy-button {
  background-color: #007bff; /* Default background color */
  color: #fff; /* Default text color */
  border: 1px solid #007bff; /* Default border */
  padding: 5px 10px; /* Adjust padding for your desired button size */
  cursor: pointer; /* Add a pointer cursor on hover */
  border-radius: 5px; /* Rounded corners */
  width: 100px;
  height: 40px;

  /* Transition to smooth out the effect */
  transition: background-color 0.2s, border-color 0.2s, transform 0.1s;
}

.buy-button:active,
.buy-button:focus {
  background-color: #004691; /* Darker background when pressed or focused */
  border-color: #004691; /* Darker border when pressed or focused */
  transform: translateY(1px); /* Slight downward shift when pressed */
}

.cancel-button {
  background-color: #FF0000 !important; /* Light red background color for "Cancel" button */
  color: #FFDDDD !important; /* Dark red text color for "Cancel" button */
  border: none;
  padding: 5px 10px;
  cursor: pointer; /* Add a pointer cursor on hover */
  border-radius: 5px;
  width: 100px;
  height: 40px;

}

.cancel-button:hover {
  background-color: #d80404; /* Keep the same color on hover to indicate inactivity */
}

.na-button {
  background-color: #d3d3d3; /* Light gray background for disabled appearance */
  color: #808080; /* Dark gray text to indicate the button is inactive */
  border: none;
  padding: 5px 10px;
  cursor: not-allowed; /* Change cursor to indicate the button is not clickable */
  border-radius: 5px;
  width: 100px;
  height: 40px;

}

.na-button:hover {
  background-color: #d3d3d3; /* Keep the same color on hover to indicate inactivity */
}

/* Fixed header */
.padded-header {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px;
}

.price-diff-green, .price-diff-red {
  font-weight: bold;
  font-size: 2.2rem;
  align-items: center;
  justify-content: center;
  height: 40px; /* Adjust to match button height */
  width: 100px; /* Adjust to match button width */
}

.price-diff-green {
  color: green;
}

.price-diff-red {
  color: red;
}


/* Content container with scroll 
.table-wrapper {
  max-height: calc(100vh - 50px); /* Adjust as needed, considering the header's height 
  
  overflow-y: false;
}
*/
/* Centered Modal Styles */
.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  z-index: 999; /* Ensure the modal is above other content */
}

.modal-content {
  background-color: #fff;
  max-width: 80%;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  text-align: center;
}

.modal-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.modal-message {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.modal-button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: #0056b3;
}

.submit-button {
  position: relative;
  z-index: 1000;
}

.id-link {
  color: inherit; /* Inherit text color from the parent element */
  text-decoration: inherit; /* Inherit text decoration (e.g., underline) from the parent element */
}
.emoji-legend-horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.emoji-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.emoji-item {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Adjust the margin as needed */
}

.emoji-item span {
  margin-right: 5px; /* Adjust the spacing between emoji and label */
}

/* Media query for mobile devices (adjust max-width as needed) */
@media (max-width: 768px) {
  .emoji-legend-horizontal {
    flex-wrap: wrap; /* Allow items to wrap to the next row */
    justify-content: flex-start; /* Start from the left */
  }

  .emoji-item {
    margin-right: 10px; /* Reduce the margin for mobile */
    margin-bottom: 5px; /* Add space below each item */
  }
}