@import "src/styles/mixins.module";
@import "src/styles/variables.module";

.success {
  color: $aqua;
}

.failure {
  color: $red;
}

.hash {
  color: $battleship;
  font-size: 12px;
  text-align: center;
}

.feedback {
  text-align: center;
  margin: 20px 0 40px;
  color: $deepgray;
  font-size: 14px;
  word-break: break-all;
}

.success {
  color: #0222ba;
  font-size: 28px;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.failure {
  color: #fd3303;
  font-size: 28px;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
