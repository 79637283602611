/* Colors */
$bg: #121a2f;
$darkblue: #ffffff;
$realblue: #0222ba;
$semiblue: #e2e7ff;

$gray: #cccccc;
$brown: #a0a0a0;
$light: #f0f0f0;
$battleship: #6d738c;
$slate: #505466;
$deepgray: #5c5c5c;

$blue: #0222ba;
$aqua: #47d7e2;
$red: #e64c57;
$orange: #CD6141;

$text: $gray;

$colors: (
  bg: $bg,
  darkblue: $darkblue,
  battleship: $battleship,
  slate: $slate,
  blue: $blue,
  aqua: $aqua,
  red: $red,
  orange: $orange,
);

$hr-shadow: fade-out(black, 0.8);
$hr: fade-out(white, 0.92);
$border: 1px solid fade-out($slate, 0.8);

/* Fonts */
$font-size: 14px;
$font-family: "Poppins";
$font-family-swap: "Open Sans", arial, sans-serif;

/* Sizes */
$breakpoint: 1120px;
$gutter: 16px;
$line-height: 1.5;
$nav-height: 70px;
$footer-height: 50px;
$footer-height-mobile: 100px;

$card-padding-horizontal: 25px;
$card-padding-header: 20px $card-padding-horizontal;
$card-padding-main: $card-padding-header;

$modal-width: 460px;

/* Transitions */
$transition-duration: 0.2s;

/* Z-indexes */
$zindex-sticky: 1020;
